@import url("https://fonts.googleapis.com/css?family=Montserrat:400,500,700,900&display=swap");

body,
html {
  margin: 0;
  padding: 0;
}

* {
  box-sizing: border-box;
}

html {
  height: 100%;
}

body {
  font-family: "Montserrat";
  /* background-color: #f2f2f2; */
  background-color: #00adbb;

  min-height: 100%;
}

footer {
  margin-top: auto;
  background-color: #00adbb;
}

* {
  box-sizing: border-box;
}
