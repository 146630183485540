.footer {
  margin-top: auto;
  padding: 3em;
  background-color: #002a48;
  margin-top: 2em;
}

.copyright {
  font-size: 0.8em;
  color: #fff;
}

.socials {
}
